.container {
  margin: 0 auto;
  max-width: var(--container-l);
  padding: 0 16px;
  width: 100%;

  @media (--viewport-m) {
    padding: 0 24px;
  }
}

.small {
  max-width: var(--container-s);
}

.medium {
  max-width: var(--container-m);
}
