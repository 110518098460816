.container {
  padding: 16px;
  position: relative;
  width: 100%;

  &::before {
    content: "";
    border-bottom: 10px solid var(--color-white);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    height: 0;
    right: 20%;
    position: absolute;
    top: 0;
    transform: translate(-50%, -100%);
    width: 0;
  }

  @media (--viewport-m) {
    background: var(--color-white);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
  }
}

.title {
  color: var(--color-grey-50);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-s);
  margin: 0 0 0.5em;
}

.companiesList > :not(:last-child) {
  margin: 0 0 12px;
}

.divider {
  background-color: var(--color-grey-30);
  border: 0;
  height: 1px;
  margin: 12px 0;
  width: 100%;
}

.link {
  background-color: transparent;
  border: 0;
  color: var(--color-grey-60);
  cursor: pointer;
  display: block;
  font-size: var(--font-size-m);
  line-height: var(--line-height-s);
  margin: 0;
  padding: 0;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &:not(:last-child) {
    margin-bottom: 0.5em;
  }
}

.companyThumbnail {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

.companyThumbnailContent {
  display: flex;
}

.companyThumbnailImageContainer {
  margin: 0 8px 0 0;
}

.companyThumbnail.isActive .companyThumbnailImageContainer {
  margin: 0 12px 0 0;
}

.companyThumbnailName {
  color: var(--color-black);
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-s);
}

.companyThumbnailLocation {
  color: var(--color-grey-60);
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
}

.companyThumbnail.isActive .companyThumbnailName,
.companyThumbnail.isActive .companyThumbnailLocation {
  font-size: var(--font-size-m);
}

.companyThumbnailEditButtonContainer {
  margin: 12px 0 0;
}

.companyThumbnailButtonContainer {
  margin: 0 0 0 12px;
}
