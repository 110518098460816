.container {
  align-items: center;
  background-color: var(--color-white);
  border-bottom: solid 1px var(--color-grey-30);
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  padding: 0 16px;
  z-index: var(--z-index-header);

  @media (--viewport-m) {
    padding: 0 24px;

    & > :not(:last-child) {
      margin: 0 24px 0 0;
    }
  }
}

.logo {
  flex-shrink: 0;
  height: 24px;
  width: 110px;
  position: relative;

  @media (--viewport-m) {
    height: 36px;
    width: 164px;
  }
}

.mobileMenu {
  background-color: var(--color-white);
  display: none;
  height: calc(100% - 60px); /* 60px is header height */
  left: 0;
  overflow-y: scroll;
  position: absolute;
  top: 60px; /* 60px is header height */
  width: 100%;

  &.isOpen {
    display: block;

    @media (--viewport-m) {
      display: flex;
    }
  }

  @media (--viewport-m) {
    align-items: center;
    display: flex;
    height: auto;
    justify-content: space-between;
    overflow: visible;
    position: relative;
    top: 0;
    width: 100%;
  }
}

.menuButton {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  flex-shrink: 0;
  height: 48px;
  padding: 0;
  width: 48px;

  & svg {
    color: var(--color-black);
    height: 50%;
    width: 50%;
  }

  @media (--viewport-m) {
    display: none;
  }
}

.navigation {
  @media (--viewport-m) {
    place-self: center center;
    display: flex;
  }
}

.navigationLink {
  border-bottom: solid 2px transparent;
  position: relative;
  color: var(--color-grey-50);
  display: block;
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-s);
  padding: 16px 12px;
  text-decoration: none;
  transition:
    color var(--transition),
    border-color var(--transition);

  &:hover,
  &:focus {
    border-color: var(--color-black);
    color: var(--color-black);
  }

  &.active {
    border-color: var(--color-red);
    color: var(--color-black);
  }

  @media (--viewport-m) {
    font-size: var(--font-size-l);
    padding: 30px 16px;
  }

  & .badge {
    position: absolute;
    right: 5px;
    border-radius: 50%;
    background: var(--color-red);
    color: var(--color-white);
    height: 5px;
    width: 5px;
    display: block;
  }
}

.accountToggle {
  align-items: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  padding: 16px;
  text-align: left;

  @media (--viewport-m) {
    justify-content: flex-end;
    padding: 0;
    width: 300px;
  }
}

.accountToggleImageContainer {
  flex-shrink: 0;
}

.accountToggleNameContainer {
  color: var(--color-grey-60);
  font-size: var(--font-size-m);
  line-height: var(--line-height-s);
  margin: 0 16px 0 12px;
}

.accountToggleName {
  font-weight: var(--font-weight-semibold);
  color: var(--color-black);
}

.accountToggleChevron svg {
  color: var(--color-black);
  height: 15px;
  width: 15px;
}

.dropDownContainer {
  @media (--viewport-m) {
    position: absolute;
    right: 0;
    top: 90px;
    width: 290px;
    z-index: unset;
  }
}
