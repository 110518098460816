.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonContainer {
  display: flex;
  width: 150px; /* Set width so the pages stay in the same place */

  &:last-child {
    justify-content: flex-end;
  }
}

.pages {
  display: none;

  & > :not(:last-child) {
    margin: 0 8px 0 0;
  }

  @media (--viewport-m) {
    align-items: center;
    display: flex;
  }
}

.fakeButton {
  background-color: transparent;
  border-radius: var(--border-radius);
  border: solid 1px var(--color-grey-50);
  color: var(--color-grey-50);
  cursor: pointer;
  display: inline-block;
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-s);
  padding: 14px 16px;

  &.active {
    background-color: var(--color-green);
    border: solid 1px transparent;
    color: var(--color-white);
    font-weight: var(--font-weight-bold);
  }
}
