.roundImageWithIcon {
  position: relative;
}

.roundImage {
  height: 48px;
  width: 48px;
}

.roundImageSmall {
  height: 36px;
  width: 36px;
}

.roundImageWithIconImage {
  background-color: var(--color-grey-40);
  border-radius: 50%;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;

  & img {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.roundImageWithIconIcon {
  align-items: center;
  background-color: var(--color-green);
  border-radius: 50%;
  bottom: 0;
  box-shadow: var(--box-shadow);
  display: flex;
  height: 16px;
  justify-content: center;
  position: absolute;
  right: 0;
  width: 16px;

  & svg {
    color: var(--color-white);
    width: 10px;
    height: 10px;
  }
}
