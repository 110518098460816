.small {
  margin: 0 0 8px;

  @media (--viewport-l) {
    margin: 0 0 16px;
  }
}

.medium {
  margin: 0 0 16px;

  @media (--viewport-l) {
    margin: 0 0 24px;
  }
}

.large {
  margin: 0 0 24px;

  @media (--viewport-l) {
    margin: 0 0 32px;
  }
}

.extralarge {
  margin: 0 0 32px;

  @media (--viewport-l) {
    margin: 0 0 48px;
  }
}
