.tag {
  align-items: center;
  border-radius: 100px;
  border: solid 1px var(--color-grey-50);
  color: var(--color-grey-50);
  display: inline-flex;
  font-size: var(--font-size-s);
  line-height: 1;
  padding: 4px 6px;

  @media (--viewport-m) {
    padding: 6px 12px;
  }

  & svg {
    display: none;
    color: var(--color-grey-50);
    height: var(--font-size-s);
    margin: 0 8px 0 0;
    width: var(--font-size-s);

    @media (--viewport-m) {
      display: block;
    }
  }
}

.orange {
  border-color: var(--color-orange);
  color: var(--color-orange);

  & svg {
    color: var(--color-orange);
  }
}

.green {
  border-color: var(--color-green);
  color: var(--color-green);

  & svg {
    color: var(--color-green);
  }
}

.red {
  border-color: var(--color-red);
  color: var(--color-red);

  & svg {
    color: var(--color-red);
  }
}
