.container {
  & > :not(:last-child) {
    margin: 0 0 12px;
  }

  @media (--viewport-m) {
    align-items: center;
    display: flex;
    justify-content: space-between;

    & > :not(:last-child) {
      margin: 0 24px 0 0;
    }
  }
}
