.container {
  & p,
  & ul,
  & ol {
    color: var(--color-black);
    font-size: var(--font-size-m);
    line-height: var(--line-height-m);
    margin: 0;

    &:not(:last-child) {
      margin: 0 0 1em;
    }
  }

  & ul,
  & ol {
    padding: 0 0 0 2em;
    margin: 1em 0;
  }

  & a {
    color: var(--color-red);
  }

  & h1,
  & h2,
  & h3,
  & h4 {
    color: var(--color-black);
    line-height: var(--line-height-s);
    margin: 0;

    &:not(:last-child) {
      margin: 0 0 0.5em;
    }
  }

  & h1,
  & h2 {
    font-weight: var(--font-weight-bold);
  }

  & h3,
  & h4 {
    font-weight: var(--font-weight-semibold);
  }

  & h1 {
    font-size: var(--font-size-xl);

    @media (--viewport-m) {
      font-size: var(--font-size-xxl);
    }

    @media (--viewport-l) {
      font-size: var(--font-size-xxxl);
    }
  }

  & h2 {
    font-size: var(--font-size-l);

    @media (--viewport-m) {
      font-size: var(--font-size-xl);
    }

    @media (--viewport-l) {
      font-size: var(--font-size-xxl);
    }
  }

  & h3 {
    font-size: var(--font-size-m);

    @media (--viewport-m) {
      font-size: var(--font-size-l);
    }

    @media (--viewport-l) {
      font-size: var(--font-size-xl);
    }
  }

  & h4 {
    font-size: var(--font-size-m);

    @media (--viewport-l) {
      font-size: var(--font-size-l);
    }
  }
}
