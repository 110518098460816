.loader {
  align-items: center;
  display: flex;
  justify-content: center;

  & svg {
    color: var(--color-green);
    height: 32px;
    width: 32px;
  }
}
