.container {
  background-color: var(--color-grey-30);
  color: var(--color-text-light);
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  padding: 12px 0;

  @media (--viewport-m) {
    padding: 20px 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > :not(:last-child) {
    margin: 0 0 8px;
  }

  @media (--viewport-m) {
    flex-direction: row;
    justify-content: space-between;

    & > :not(:last-child) {
      margin: 0 16px 0 0;
    }
  }
}

.linksContainer {
  align-items: center;
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin: 0 0 8px;
  }

  @media (--viewport-m) {
    flex-direction: row;

    & > :not(:last-child) {
      margin: 0 24px 0 0;
    }
  }
}

.link {
  color: var(--color-text-light);
  transition: color var(--transition);

  &:hover,
  &:focus {
    color: var(--color-black);
  }
}
