.container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin: 0 0 12px;
  }

  @media (--viewport-m) {
    align-items: stretch;
    flex-direction: row;

    & > :not(:last-child) {
      margin: 0 16px 0 0;
    }
  }
}
