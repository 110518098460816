.container {
  padding: 60px 0;

  @media (--viewport-m) {
    padding: 120px 0;
  }
}

.content {
  margin: 0 auto;
  max-width: var(--container-s);
  text-align: center;
  width: 100%;

  & > svg {
    color: var(--color-green);
    height: 48px;
    margin: 0 0 12px;
    width: 48px;

    @media (--viewport-m) {
      margin: 0 0 24px;
    }
  }
}

.richTextContainer:not(:last-child) {
  margin: 0 0 12px;

  @media (--viewport-m) {
    margin: 0 0 24px;
  }
}
