.title {
  @media (--viewport-m) {
    width: 30%;
  }
}

.container {
  align-items: center;
  background: var(--color-white);
  border-radius: var(--border-radius);
  color: var(--color-black);
  display: flex;
  font-size: var(--font-size-m);
  line-height: 1;
  padding: 10px;
  text-decoration: none;
  transition: box-shadow var(--transition);

  &:hover,
  &:focus {
    box-shadow: var(--box-shadow);

    & .title {
      text-decoration: underline;
    }
  }
}

.imageContainer {
  background-color: var(--color-green);
  border-radius: var(--border-radius);
  display: block;
  flex-shrink: 0;
  height: 60px;
  overflow: hidden;
  position: relative;
  width: 80px;

  & img {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.dataContainer {
  align-items: center;
  display: block;
  flex-grow: 1;
  margin: 0 12px;

  @media (--viewport-m) {
    display: flex;
    margin: 0 24px;
  }

  & > :not(:last-child) {
    margin: 0 0 8px;

    @media (--viewport-m) {
      margin: 0 8px 0 0;
    }
  }

  & > * {
    display: none;

    @media (--viewport-m) {
      display: block;
    }
  }

  & > :first-child,
  & > :last-child,
  & > :nth-child(2) {
    display: block;
  }
}

.column {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (--viewport-m) {
    width: calc((100% - 30%) / 4);
  }
}

.fakeButtonContainer {
  display: none;

  @media (--viewport-m) {
    display: block;
  }
}

.fakeButton {
  align-items: center;
  border-radius: var(--border-radius);
  border: solid 1px var(--color-grey-50);
  display: flex;
  height: 48px;
  justify-content: center;
  width: 48px;

  & svg {
    color: var(--color-grey-50);
    height: 16px;
    width: 16px;
  }
}
