.container {
  align-items: center;
  color: var(--color-grey-50);
  display: none;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-s);
  padding: 0 10px;

  @media (--viewport-m) {
    display: flex;
  }
}

.imageSpacer {
  width: 80px; /* These should match the widths of card */
}

.dataContainer {
  align-items: center;
  display: flex;
  margin: 0 24px;
  flex-grow: 1;

  & > :not(:last-child) {
    margin: 0 0 8px;

    @media (--viewport-m) {
      margin: 0 8px 0 0;
    }
  }
}

.title {
  width: 30%; /* These should match the widths of card */
}

.column {
  width: calc((100% - 30%) / 4); /* These should match the widths of card */
}

.fakeButtonSpacer {
  width: 48px; /* These should match the widths of card */
}

.title,
.column {
  background-color: transparent;
  border: 0;
  color: var(--color-grey-50);
  cursor: pointer;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-s);
  padding: 0;
  text-align: left;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
