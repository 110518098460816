.subtext {
  color: var(--color-grey-50);
  display: block;
  font-family: var(--font-family-body);
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
  margin: 0;

  & a {
    color: var(--color-grey-50);
  }
}
