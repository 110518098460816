.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex: 1;
  padding: 24px 0;

  @media (--viewport-m) {
    padding: 48px 0;
  }
}
