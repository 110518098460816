.innerContainer {
  display: block;
  position: relative;
}

.inner {
  align-items: center;
  display: flex;
  font-family: var(--font-family-body);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-s);

  & > :not(:last-child) {
    margin-right: 8px;
  }

  & svg {
    flex-shrink: 0;
    height: 14px;
    transition: color var(--transition);
    width: 14px;
  }
}

.hiddenContent {
  visibility: hidden;
}

.loader {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  & svg {
    height: 15px;
    width: 15px;
  }
}

.button {
  background-color: var(--color-green);
  border-radius: var(--border-radius);
  border: solid 1px transparent;
  color: var(--color-white);
  cursor: pointer;
  display: inline-block;
  padding: 14px 16px;
  text-decoration: none;
  transition: background-color var(--transition), opacity var(--transition);

  &:hover,
  &:focus {
    background-color: var(--color-green-dark);
  }

  & > :not(:last-child) {
    display: none;
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

.secondary {
  background-color: transparent;
  border: solid 1px var(--color-grey-50);
  color: var(--color-grey-50);

  & .inner {
    font-weight: var(--font-weight-regular);
  }

  &:hover,
  &:focus {
    border-color: transparent;
    color: var(--color-white);
  }
}

.danger {
  background-color: var(--color-red);
  border: solid 1px var(--color-red);
  color: var(--color-white);

  &:hover,
  &:focus {
    background-color: var(--color-red-dark);
    border-color: transparent;
    color: var(--color-white);
  }
}

.small {
  padding: 6px 8px;
}
